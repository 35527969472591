/* global Office */

Office.onReady(() => {});

function action(event: Office.AddinCommands.Event) {
  const message: Office.NotificationMessageDetails = {
    type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
    message: "Performed action.",
    icon: "Icon.80x80",
    persistent: true,
  };

  /* show a notification message. */
  Office.context.mailbox.item?.notificationMessages.replaceAsync("ActionPerformanceNotification", message);

  /* complete event */
  event.completed();
}

/* register office actions */
Office.actions.associate("action", action);
